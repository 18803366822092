module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"wordpress":[{"protocol":"https","domain":"nodustagri.lignostar.com","language":"nl","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustagri.lignostar.com","language":"en","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]},{"protocol":"https","domain":"nodustagri.lignostar.com","language":"de","normalizers":[{"name":"acf"}],"includedRoutes":["**/projects"]}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NoDustAgri.com","short_name":"NoDustAgri","start_url":"/?pwa=true","background_color":"black","theme_color":"#81c136","display":"minimal-ui","icon":"src/images/favicon.png","lang":"nl","localize":[{"start_url":"/en/","lang":"en"},{"start_url":"/de/","lang":"de"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"71f9e6ea34dad6429eabc9c6f99743a9"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KXLRF8HT2L","UA-36988470-3"],"pluginConfig":{"head":false,"respectDNT":true,"anonymize_ip":true,"exclude":[]}},
    }]
